import React from 'react'
import styled from 'styled-components'
import { Button, useWalletModal } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { languageList } from 'config/localization/languages'
import useAuth from 'hooks/useAuth'

const StyledConnectWalletButton = styled(Button)`
  background: var(--blackColor);
  border-radius: 20px;
  // border: 2px solid #FF0D10;
  color: var(--whiteColor);
  box-shadow: none;
  font-weight: 400;
`

const ConnectWalletButton = (props) => {
  const { t, currentLanguage, setLanguage } = useTranslation()
  const { login, logout } = useAuth()
  const { account } = props
  const { onPresentAccountModal } = useWalletModal(login, logout, account, {
    copyText   : t("Copy Address"),
    logoutText : t("Logout"),
    linkText   : t("View on BscScan"),
    copiedText : t("Copied")
  })
  const address = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null

  return (
    <StyledConnectWalletButton onClick={onPresentAccountModal} {...props}>
      {address}
    </StyledConnectWalletButton>
  )
}

export default ConnectWalletButton
