import { useCallback } from 'react'
import axios from 'axios'
import { useTokenCashierContract, useTokenCashierContractV2 } from 'hooks/useContract'
import { filterType } from '../constants'

const determineParam = (_page: number, _limit: number, _filterType?: filterType, _statusFilter?: string, _categoryFilter?: string) => {
  const pageParamStr = `page=${_page}&per-page=${_limit}`
  const statusParamStr = (_statusFilter) ? `&status=${_statusFilter}` : ''
  const categoryParamStr = (_categoryFilter) ? `&category_name=${_categoryFilter}` : ''
  return `?is_poll=0&${pageParamStr}${statusParamStr}${categoryParamStr}`
}

// To get project list
export const useProjectList = () => {
  const handleProjectList = useCallback(async (_page = 1, _limit = 10, _filterType?: filterType, _statusFilter?: string, _categoryFilter?: string) => {
    try {
      const baseUrl = `${process.env.REACT_APP_BO_LINK}/api/charity/get-project${determineParam(_page, _limit, _filterType, _statusFilter, _categoryFilter)}`

      const response = await axios.get(baseUrl)
      const { data, error, code, message } = await response?.data
      if (error || code !== 200) throw new Error(message)

      if (data && data.length > 0) {
        return {
          data,
          max: data[0].max_count_per_page
        }
      }
      return {
        data,
        max: 0
      }
    } catch (e) {
      return {
        data: [],
        max: 0
      }
    }
  }, [])

  return { onProjectList: handleProjectList }
}

// To get social media links
export const useSocialLinks = () => {
  const handleSocialLinks = useCallback(async () => {
    try {
      const baseUrl = `${process.env.REACT_APP_BO_LINK}/api/frontend/get-social-link`

      const response = await axios.get(baseUrl)

      const { data, error, code, message } = await response?.data
      if (error || code !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message: ""
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onSocialLinks: handleSocialLinks }
}

// To get user activation status, kyc status and form data if available
export const useProjectStatusInfo = (account) => {
  const handleProjectStatusInfo = useCallback(async () => {
    try {
      const baseUrl = `${process.env.REACT_APP_BO_LINK}/api/charity/get-status`
      const response = await axios.get(baseUrl, {
        params: {
          wallet_address: account
        }
      })
      const { data, error, code, message } = await response?.data
      if (error || code !== 200) throw new Error(message)
      return data
    } catch (e) {
      return false
    }
  }, [account])

  return { onProjectStatusInfo: handleProjectStatusInfo }
}

// To get purchase history
export const useGetPurchaseHistory = (_account: string, _pid) => {
  const handleGetPurchaseHistory = useCallback(async () => {
    try {
      const baseUrl = `${process.env.REACT_APP_BO_LINK}/api/charity/get-purchased-history`

      const response = await axios.get(baseUrl, {
        params: {
          wallet_address: _account,
          id: _pid
        }
      })

      const { data, error, code, message } = await response?.data
      if (error || code !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message: ""
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: [],
        message
      }
    }
  }, [_account, _pid])

  return { onGetPurchaseHistory: handleGetPurchaseHistory }
}

// To purchase
export const usePurchase = (_account: string) => {
  const handlePurchase = useCallback(async (projectId, amount: number, isExtra: boolean, paymentAddress: string) => {
    try {
      const baseUrl = `${process.env.REACT_APP_DOMAIN_LINK}purchase`

      const response = await axios.post(baseUrl, {
        project_id: projectId,
        buyer: _account,
        amount,  // amount in ether (quantity)
        extra: isExtra, // true = easycoin
        payment_token: paymentAddress,
      })

      const { data, error, status, message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message: ""
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [_account])

  return { onPurchase: handlePurchase }
}

export const usePurchaseOnChain = () => {
  const tokenCashierContract = useTokenCashierContract()
  const handlePuchaseOnChain = useCallback(async (paymentToken, amount, unitPrice, round, pid, signature) => {
    const tx = await tokenCashierContract.purchase(paymentToken, amount, unitPrice, round, pid, signature)
    const receipt = await tx.wait()
  }, [tokenCashierContract])

  return { onPurchaseOnChain: handlePuchaseOnChain }
}

// To purchase v2
export const usePurchaseV2 = (_account: string) => {
  const handlePurchase = useCallback(async (projectId, round, paymentAddress: string, amount: number) => {
    try {
      const baseUrl = `${process.env.REACT_APP_DOMAIN_LINK}purchase`

      const response = await axios.post(baseUrl, {
        project_id: projectId,
        round,
        buyer: _account,
        payment_token: paymentAddress,
        amount,  // amount in ether (quantity)
      })

      const { data, error, status, message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message: ""
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [_account])

  return { onPurchase: handlePurchase }
}

export const usePurchaseOnChainV2 = () => {
  const tokenCashierContractV2 = useTokenCashierContractV2()
  const handlePuchaseOnChain = useCallback(async (respData: any) => {
    const tx = await tokenCashierContractV2.purchase(
      respData.nft_mint,
      respData.fund_receiver,
      respData.payment_token,
      respData.amount,
      respData.unit_price,
      respData.round,
      respData.pid,
      respData.max_supply,
      respData.signature
    )
    const receipt = await tx.wait()
  }, [tokenCashierContractV2])

  return { onPurchaseOnChain: handlePuchaseOnChain }
}

// To get supply info on chain i.e. total supply and current sold
export const useSupplyInfoOnChain = () => {
  const tokenCashierContract = useTokenCashierContract(false)
  const handleSupplyInfoOnChain = useCallback(async (pid, round) => {
    const tx = await tokenCashierContract.supplys(pid, round)
    return tx
  }, [tokenCashierContract])

  return { onSupplyInfoOnChain: handleSupplyInfoOnChain }
}

// To get current sold
export const useSupplyInfoOnChainV2 = () => {
  const tokenCashierContractV2 = useTokenCashierContractV2(false)
  const handleSupplyInfoOnChain = useCallback(async (pid, round) => {
    const tx = await tokenCashierContractV2.asset_sold(pid, round)
    return tx
  }, [tokenCashierContractV2])

  return { onSupplyInfoOnChain: handleSupplyInfoOnChain }
}

// To get whitelist info of an user
export const useCheckPurchase = (account: string) => {
  const handleCheckPurchase = useCallback(async () => {
    try {
      const baseUrl = `${process.env.REACT_APP_BO_LINK}/api/frontend/get-token-purchase-whitelist-status`


      const response = await axios.get(baseUrl, {
        params: {
          address: account,
        }
      })

      const { data, error, code, message } = await response?.data
      if (error || code !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message: ""
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [account])

  return { onCheckPurchase: handleCheckPurchase }
}

// To get purchasable of an user (replaced the above purchasable key of `useCheckPurchase` API)
export const useBackendCheckPurchase = (account: string, pid: string) => {
  const handleBackendCheckPurchase = useCallback(async () => {
    try {
      if (!account || !pid) throw new Error("account or pid required")
      const baseUrl = `${process.env.REACT_APP_DOMAIN_LINK}canPurchase`

      const response = await axios.get(baseUrl, {
        params: {
          buyer: account,
          project_id: pid
        }
      })

      const { data, error, status, message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message: ""
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [account, pid])

  return { onBackendCheckPurchase: handleBackendCheckPurchase }
}