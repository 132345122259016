import React, { lazy } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { useUpdateNetwork } from 'state/network/hooks'
import { usePollBlockNumber } from 'state/block/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import history from './routerHistory'
import 'react-image-gallery/styles/css/image-gallery.css'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const NotFound = lazy(() => import('./views/NotFound'))
// const ProjectDetails = lazy(() => import('./views/Projects/components/ProjectDetails'))
const ProjectList = lazy(() => import('./views/Projects'))
const ProjectDetailsGeneral = lazy(() => import('./views/Projects/components/ProjectDetailGeneral'))

// const Home = lazy(() => import('./views/Home'))
const Affiliate = lazy(() => import('./views/Affiliate'))
const ProfileV2 = lazy(() => import('./views/ProfileV2'))

const Inventory = lazy(() => import('./views/Inventory'))
const MarketPlace = lazy(() => import('./views/MarketPlace'))
// const EcoSystem = lazy(() => import('./views/Ecosystem'))
// const TokenDetails = lazy(() => import('./views/Projects/components/TokenDetails'))
const CollectionDetails = lazy(() => import('./views/MarketPlace/CollectionDetails'))
const NftDetailV2 = lazy(() => import('./views/MarketPlace/NftDetailsV2'))
const TncPolicy = lazy(() => import('./views/TncPolicy'))
const BindReferral = lazy(() => import('./views/BindReferral'))
const BuyCrypto = lazy(() => import('./views/BuyCrypto'))
const TokenOption = lazy(() => import('./views/SelectPackage'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()
  useUpdateNetwork()

  return (
    <Router history={history}>
      <div className="master-container">
        <Menu />
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>

            {/* <Route exact path="/launchpad/:projectId/details">
              <ProjectDetails />
            </Route> */}
            {/* <Route exact strict path="/launchpad/:projectId/details/:refCode" component={ProjectDetails} /> */}
            <Route exact path="/launchpad/:projectId/details">
              <ProjectDetailsGeneral  />
            </Route>
            <Route exact strict path="/launchpad/:projectId/details/:refCode" component={ProjectDetailsGeneral} />
            {/* <Route exact path="/launchpad/:projectId/tokendetails">
              <TokenDetails />
            </Route> */}
            {/* <Route exact strict path="/launchpad/:projectId/tokendetails/:refCode" component={TokenDetails} /> */}

            <Route exact strict path="/launchpad" component={ProjectList} />
            {/* <Route exact strict path="/home" component={Home} /> */}
            <Route exact strict path="/affiliate" component={Affiliate} />
            <Route exact strict path="/profile" component={ProfileV2} />
            <Route exact strict path="/inventory" component={Inventory} />
            <Route path="/marketplace" component={MarketPlace} />
            {/* <Route exact strict path="/ecosystem" component={EcoSystem} /> */}
            <Route exact path="/collection/:networkId/:contractAddress">
              <CollectionDetails />
            </Route>
            <Route exact path="/collection/:networkId/:contractAddress/:tokenId">
              <NftDetailV2 />
            </Route>
            <Route exact path="/policy">
              <TncPolicy cmsType="privacy" />
            </Route>
            <Route exact path="/tnc">
              <TncPolicy cmsType="tnc" />
            </Route>
            <Route exact strict path="/signup" component={BindReferral} />
            <Route exact strict path="/buy-crypto" component={BuyCrypto} />
            <Route exact strict path="/token-option" component={TokenOption} />

            {/* Redirect */}
            <Redirect exact from="/" to="/launchpad" />
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
        <ToastListener />
        <DatePickerPortal />
      </div>
    </Router>
  )
}

export default React.memo(App)
