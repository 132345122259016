import React from 'react'
import styled from 'styled-components'
import { Button, useWalletModal } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'

const MConnectWalletButton = styled(Button)`
  background: var(--blackColor);
  border-radius: 20px;
  // border: 2px solid #FF0D10;
  color: var(--whiteColor);
  box-shadow: none;
  font-weight: 400;
`

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, undefined, {})

  return (
    <MConnectWalletButton onClick={onPresentConnectModal} {...props}>
      {t('Connect Wallet')}
    </MConnectWalletButton>
  )
}

export default ConnectWalletButton
