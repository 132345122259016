import random from 'lodash/random'
import { CHAIN_ID, NETWORK_RPC } from 'config/constants/networks'

const getNodeUrl = (chainId: number) => {
  const randomIndex = random(0, NETWORK_RPC[chainId].length - 1)
  return NETWORK_RPC[chainId][randomIndex]
}

export const getSupportedNodeUrl = (chainIdList: CHAIN_ID[]) => {
  const rpcListArr = chainIdList.map(c => ({[c]: getNodeUrl(c) }))
  const rpcListObj = Object.assign({}, ...rpcListArr)
  return rpcListObj
}

export default getNodeUrl
