import React, { useEffect, useState, useMemo, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { useResizeDetector } from 'react-resize-detector'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AccountWalletButton from 'components/AccountWalletButton'
// import MotionTextButton from 'components/Shared/MotionTextButton'
// import MotionOnlyText from 'components/Shared/MotionText'
import { useTranslation } from 'contexts/Localization'
import { useSocialLinks } from 'views/Projects/hooks/useProject'
import { languageList } from 'config/localization/languages'
import LangSelector from '../LangSelector'
// import NetworkSelector from './NetworkSelector'

const StyledI = styled.i`
  color: #624AFC;
`
const StyledMobileImg = styled.img`
  @media only screen and (max-width: 767px) {
    display: none;
  }
`
const SocialPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 25px;
  max-height: 75px;

  @media only screen and (max-width: 1199px) {
    display: none;
  }
`
const StyledSocialPanelI = styled.i`
  color: var(--mainThemeColor);
  border-radius: 13px;
  padding: 4px;
`
const SvgWrapper = styled.div`
  svg {
    fill: var(--mainThemeColor);
  }
`
const CustomSvgWrapper = styled.div`
  svg {
    fill: var(--mainThemeColor);
  }
  background: var(--mainThemeColor);
  border-radius: 13px;
  padding: 0px 4px 2px;
`
const MobileSocialPanel = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
  margin-bottom: 5px;

  p {
    color: white;
    margin-bottom: 0;
  }
  p:last-child {
    margin-left: 5px;
  }

  @media only screen and (max-width: 1399px) {
    display: flex;
  }
`
// custom li to hide menu item in non mobile view
const CustomLi = styled.li`
  display: none !important;

  @media only screen and (max-width: 1199px) {
    display: inline-block !important;
  }
`
const StyledButton = styled.button<{ bgColorCode?: string; border?: string; colorCode?: string; padding?: string; height?: string; }>`
  background-color: ${props => props.bgColorCode === undefined ? "#D24C3F" : props.bgColorCode};
  color: ${props => props.colorCode === undefined ? "#FFFFFF" : props.colorCode};
  max-height: 43px;
  box-shadow: none;
  border-radius: 10px;

  ${({ border }) =>
    border &&
    css`
        border: ${border};
      `
  }
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
      `
  }
  ${({ height }) =>
    height &&
    css`
      height: ${height};
      `
  }
`
const StyledText = styled.div <{ fSize?: string; fWeight?: string; colorCode?: string; whiteSpace?: string; textDeco?: string; fFamily?: string; }>`
  color: ${props => props.colorCode === undefined ? "#000000" : props.colorCode};
  font-style: normal;
  font-weight: ${props => props.fWeight === undefined ? "500" : props.fWeight};
  font-family: ${props => props.fFamily === undefined ? "var(--fontFamily)" : props.fFamily};
  font-size: ${props => props.fSize === undefined ? "1rem" : props.fSize};
  // text-align: center;
  line-height: 1.5;

  ${({ whiteSpace }) =>
    whiteSpace &&
    css`
       white-space: ${whiteSpace};
      `
  }
  ${({ textDeco }) =>
    textDeco &&
    css`
       text-decoration: ${textDeco};
      `
  }
`
const DropdownFlex = styled.div`
  display: flex;
  flex-direction: column;

  // > li > a {
  //   color: #AFAFAF !important;
  // }
`

const CustomIcon = styled.img`
  width: 25px;
`

const Menu = () => {
  const { account } = useWeb3React()

  const [showMenu, setshowMenu] = useState(false)
  const [showSubMenu, setShowSubMenu] = useState(false)

  const toggleMenu = () => {
    setshowMenu(!showMenu)
    if (showMenu === false && showSubMenu) {
      toggleSubMenu()
    }
  }

  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu)
  }

  const { t, setLanguage, currentLanguage } = useTranslation()
  const location = useLocation()
  const { pathname } = location
  const splitLocation = pathname.split("/")

  const initSocialLinks = useMemo(() => ({
    "telegram": "",
    "twitter": "",
    "discord": "",
    "medium": "",
    "instagram": "",
  }), [])
  const [socialLinks, setSocialLinks] = useState(initSocialLinks)
  const { onSocialLinks: handleSocialLinks } = useSocialLinks()

  const onResize = useCallback(() => {
    setshowMenu(false)
  }, [])
  const { ref } = useResizeDetector({ onResize })

  useEffect(() => {
    const getSocialLinks = async () => {
      const result = await handleSocialLinks()
      if (result.error === false) {
        setSocialLinks(result.data)
      } else {
        setSocialLinks(initSocialLinks)
      }
    }
    // getSocialLinks()
  }, [handleSocialLinks, initSocialLinks])

  return (
    <>
      <div id='navbar' className='navbar-area'>
        <div className='raimo-responsive-nav'>
          <div ref={ref} className='container'>
            <div className='raimo-responsive-menu'>
              <div role='presentation' onClick={() => toggleMenu()} className='hamburger-menu'>
                {showMenu ? (
                  <StyledI className='bx bx-x' />
                ) : (
                  <StyledI className='bx bx-menu' />
                )}
              </div>
              <div className='logo'>
                <a href='/'>
                  <StyledMobileImg src='/images/logo/AirWip_logo_Color.png?v=1' alt='logo' style={{ maxWidth: '110px' }} />
                </a>
              </div>
              <div className='responsive-others-option'>
                <div className='d-flex align-items-center'>
                  <div className='option-item'>
                    <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />
                  </div>
                  {/* <div className='option-item'>
                    <NetworkSelector />
                  </div> */}
                  <div className='option-item'>
                    {!account ? <ConnectWalletButton /> : <AccountWalletButton account={account} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className={showMenu ? 'show navbar navbar-expand-md navbar-light' : 'navbar navbar-expand-md navbar-light hide-menu'}>
          <div className='container'>
            <a href='/' className='navbar-brand'>
              <img src='/images/logo/AirWip_logo_Color.png' alt='logo' style={{ maxWidth: '130px' }} />
            </a>
            <div className='collapse navbar-collapse mean-menu' role="presentation" onClick={() => toggleMenu()}>
              <ul className='navbar-nav'>
                {/* <li className='nav-item'>
                  <Link to="/home" className={`nav-link ${splitLocation[1] === "home" ? "active" : ""}`}>{t("Home")} </Link>
                </li> */}
                <li className='nav-item'>
                  <Link to="/launchpad" className={`nav-link ${splitLocation[1] === "projects" ? "active" : ""}`}>{t("Launchpad")} </Link>
                </li>
                {/* <li className='nav-item'>
                  <a href="https://blockchain-service.s3.ap-southeast-1.amazonaws.com/masverse/files/Masverse_Roadmap.pdf" target="_BLANK" rel="noopener noreferrer">
                    {t("Roadmap")}
                  </a>
                </li> */}
                <li className='nav-item'>
                  <Link to="/affiliate" className={`nav-link ${splitLocation[1] === "affiliate" ? "active" : ""}`}>{t("Affiliate")} </Link>
                </li>
                {/* <li className='nav-item'>
                  <Link to="/ecosystem" className={`nav-link ${splitLocation[1] === "ecosystem" ? "active" : ""}`}>{t("Ecosystem")} </Link>
                </li> */}
                <li className='nav-item'>
                  <Link to="/marketplace" className={`nav-link ${splitLocation[1] === "marketplace" ? "active" : ""}`}> {t("Marketplace")} </Link>
                </li>
                <li className='nav-item'>
                  <Link to="/inventory" className={`nav-link ${splitLocation[1] === "inventory" ? "active" : ""}`}> {t("Inventory")} </Link>
                </li>
                <li className='nav-item'>
                  <Link to="/profile" className={`nav-link ${splitLocation[1] === "profile" ? "active" : ""}`}>{t("Profile")} </Link>
                </li>
                <li className='nav-item'>
                  <Link to="/buy-crypto" className={`nav-link ${splitLocation[1] === "buy-crypto" ? "active" : ""}`}>{t("Buy Crypto")} </Link>
                </li>
                {/* <li className='nav-item'>
                  <Link to="/profile" className={`nav-link ${splitLocation[1] === "profile" ? "active" : ""}`} style={{ height: "100%" }}>
                    <MotionOnlyText textString="Profile" textHeightMargin={10} textWidthMargin={10}
                      textProps={{
                        colorCode: splitLocation[1] === "profile" ? "var(--blackColor)" : "var(--greyColor)",
                        className: "nav-text"
                      }}
                    />
                  </Link>
                </li> */}
                {/* <MobileSocialPanel>
                  {socialLinks.instagram ?
                    <a className="text-white" href={socialLinks.instagram} target="_BLANK" rel="noopener noreferrer">
                      <CustomIcon src="/images/social/instagram.svg?v=2" alt="instagram" />
                    </a>
                    : null}
                  {socialLinks.medium ?
                    <a className="text-white" href={socialLinks.medium} target="_BLANK" rel="noopener noreferrer">
                      <CustomIcon src="/images/social/medium.svg?v=2" alt="medium" />
                    </a>
                    : null}
                  {socialLinks.telegram ?
                    <a className="text-white" href={socialLinks.telegram} target="_BLANK" rel="noopener noreferrer">
                      <CustomIcon src="/images/social/telegram.svg?v=2" alt="telegram" />
                    </a>
                    : null}
                  {socialLinks.twitter ?
                    <a className="text-white" href={socialLinks.twitter} target="_BLANK" rel="noopener noreferrer">
                      <CustomIcon src="/images/social/twitter.svg?v=2" alt="twitter" />
                    </a>
                    : null}
                  {socialLinks.discord ?
                    <a className="text-white" href={socialLinks.discord} target="_BLANK" rel="noopener noreferrer">
                      <CustomIcon src="/images/social/discord.svg?v=2" alt="discord" />
                    </a>
                    : null}
                </MobileSocialPanel> */}
              </ul>
              <div className='others-option'>
                <div className='d-flex align-items-center'>
                  {/* <SocialPanel className='mr-30px mr-50px'>
                    {socialLinks.instagram ?
                      <a className="text-white" href={socialLinks.instagram} target="_BLANK" rel="noopener noreferrer">
                        <CustomIcon src="/images/social/instagram.svg?v=2" alt="instagram" />
                      </a>
                      : null}
                    {socialLinks.medium ?
                      <a className="text-white" href={socialLinks.medium} target="_BLANK" rel="noopener noreferrer">
                        <CustomIcon src="/images/social/medium.svg?v=2" alt="medium" />
                      </a>
                      : null}
                    {socialLinks.telegram ?
                      <a className="text-white" href={socialLinks.telegram} target="_BLANK" rel="noopener noreferrer">
                        <CustomIcon src="/images/social/telegram.svg?v=2" alt="telegram" />
                      </a>
                      : null}
                    {socialLinks.twitter ?
                      <a className="text-white" href={socialLinks.twitter} target="_BLANK" rel="noopener noreferrer">
                        <CustomIcon src="/images/social/twitter.svg?v=2" alt="twitter" />
                      </a>
                      : null}
                    {socialLinks.discord ?
                      <a className="text-white" href={socialLinks.discord} target="_BLANK" rel="noopener noreferrer">
                        <CustomIcon src="/images/social/discord.svg?v=2" alt="discord" />
                      </a>
                      : null}
                  </SocialPanel> */}
                  <div className='option-item'>
                    <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />
                  </div>
                  {/* <div className='option-item'>
                    <NetworkSelector />
                  </div> */}
                  <div className='option-item'>
                    {!account ? <ConnectWalletButton /> : <AccountWalletButton account={account} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Menu
