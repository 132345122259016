export default {
  charityContr: {
    1: '0xbE8e5a56B9482c759926cebF7ACce0E330385D6f',
    4: '0x7262B54A0D48AD08166Fd574b6cfB65bD098C6C4',
    56: '',
    97: ''
  },
  charityTreasuryContr: {
    1: '0xdFf4Ee74679C35CdC40776bFaF1d2f059EF383c0',
    4: '0xb57f15f975C2F2a7Bed9795F35660bf90DAdB756',
    56: '',
    97: ''
  },
  nftContr: {
    1: '0x9363c2523593442cCf5cDd3B0e2d766767ed6f67',
    4: '0x7869B2215b20c79753c1b44C6844b015eF24672d',
    56: '',
    97: ''
  },
  nftStakingContr: {
    1: '0x9aaC920d841406F9421C00AD5d504df951E32770',
    4: '0xE5ecBE448F9ACf574A1BF539fC4A7Ff317e74A57',
    56: '',
    97: ''
  },
  nftCashierContr: {
    1: '0x2176197857c1F3eB7EB51E1298Fd2F9d3Ed96a11',
    4: '0x9cc51FCE778EC259Ac8293518F442a98de0A5302',
    56: '',
    97: ''
  },
  tokenCashierContr: {
    1: '',
    4: '',
    56: '0x558ff913C2A7ef80f293dD099D192E1C4f57B3b7',
    97: ''
  },
  tokenCashierContrV2: {
    1: '',
    4: '',
    56: '0xF9D4734412E2C43f3bA81011c5D8C70d6a2B06A0',
    97: ''
  },
  claimHandlerContr: {
    1: '',
    4: '',
    56: '0x690BFd0C3eDeF5aeB331848b333813A464b438B7',
    97: ''
  },
  marketPlaceContr: {
    1: '',
    4: '',
    56: '0x8dD71725680c377c0c8947cd7a37bF44de377E0B',
    // 56: '0x1350b931d4Ea75B5eFDee41aa198F144De13e0Dd',
    97: ''
  },
  multiCall: {
    1: '0x2a80799F88896E16f963284C7583f365CD645A1B',
    4: '0x66ab763FC0e25018BB6B693feFdE0AB31D67EB3c',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  }
}
