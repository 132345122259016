import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address, chainId: number): string => {
  if (!address) return ""
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getCharityContrAddress = (chainId: number) => {
  return getAddress(addresses.charityContr, chainId)
}

export const getCharityTreasuryContrAddress = (chainId: number) => {
  return getAddress(addresses.charityTreasuryContr, chainId)
}

export const getNftContrAddress = (chainId: number) => {
  return getAddress(addresses.nftContr, chainId)
}

export const getNftStakingContrAddress = (chainId: number) => {
  return getAddress(addresses.nftStakingContr, chainId)
}

export const getNftCashierContrAddress = (chainId: number) => {
  return getAddress(addresses.nftCashierContr, chainId)
}

export const getTokenCashierContrAddress = (chainId: number) => {
  return getAddress(addresses.tokenCashierContr, chainId)
}

export const getTokenCashierContrV2Address = (chainId: number) => {
  return getAddress(addresses.tokenCashierContrV2, chainId)
}

export const getClaimHandlerContrAddress = (chainId: number) => {
  return getAddress(addresses.claimHandlerContr, chainId)
}

export const getMarketPlaceContrAddress = (chainId: number) => {
  return getAddress(addresses.marketPlaceContr, chainId)
}

export const getMulticallAddress = (chainId: number) => {
  return getAddress(addresses.multiCall, chainId)
}
