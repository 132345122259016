import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import {
  getBep20Contract,
  getErc721Contract,
  getCharityContract
} from 'utils/contractHelpers'
import {
  getNftContrAddress,
  getNftStakingContrAddress,
  getCharityContrAddress,
  getNftCashierContrAddress,
  getCharityTreasuryContrAddress,
  getTokenCashierContrAddress,
  getClaimHandlerContrAddress,
  getMarketPlaceContrAddress,
  getTokenCashierContrV2Address
} from 'utils/addressHelpers'

// Imports below migrated from Exchange useContract.ts
import { Contract } from '@ethersproject/contracts'
import { ChainId, WETH } from '@pancakeswap/sdk'
import NFT_STAKING_ABI from 'config/abi/nftStaking.json'
import NFT_CASHIER_ABI from 'config/abi/nftCashier.json'
import NFT_ABI from 'config/abi/nft.json'
import CHARITY_ABI from 'config/abi/charity.json'
import CHARITY_TREASURY_ABI from 'config/abi/charityTreasury.json'
import TOKEN_CASHIER_ABI from 'config/abi/tokenCashier.json'
import CLAIM_HANDLER_ABI from 'config/abi/claimHandler.json'
import MARKETPLACE_ABI from 'config/abi/marketplace.json'
import TOKEN_CASHIERV2_ABI from 'config/abi/tokenCashierV2.json'
import ENS_PUBLIC_RESOLVER_ABI from '../config/abi/ens-public-resolver.json'
import ENS_ABI from '../config/abi/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../config/abi/erc20'
import ERC20_ABI from '../config/abi/erc20.json'
import WETH_ABI from '../config/abi/weth.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../config/constants/multicall'
import { getContract, getContractMulChain } from '../utils'

/**
 * Helper hooks to get specific contracts (by ABI)
 */
export const useERC20 = (address: string, chainId: number) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBep20Contract(address, chainId, library.getSigner()), [address, library, chainId])
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address: string, chainId: number) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getErc721Contract(address, chainId, library.getSigner()), [address, library, chainId])
}

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true, chainId?: number): Contract | null {
  const { library, account } = useActiveWeb3React(chainId)

  return useMemo(() => {
    if (!address || address === '0x0000000000000000000000000000000000000000' || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}
// returns null on errors
function useContractMulChain(address: string | undefined, ABI: any, chainId: number, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || address === '0x0000000000000000000000000000000000000000' || !ABI || !library) return null
    try {
      return getContractMulChain(address, ABI, chainId, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, chainId, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    // eslint-disable-next-line default-case
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.TESTNET:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}

export const useCharityContract = (signer = true) => {
  const { library, chainId } = useActiveWeb3React()
  const withSigner = useMemo(() => getCharityContract(chainId, library.getSigner()), [chainId, library])
  const noSigner = useMemo(() => getCharityContract(chainId, undefined), [chainId])
  return signer ? withSigner : noSigner
}

export const useNftContractByAddr = (contractAddr: string, signer = true) => {
  return useContract(contractAddr, NFT_ABI, signer)
}
export const useNftContractByAddrMulChain = (contractAddr: string, chainId: number, signer = true) => {
  return useContractMulChain(contractAddr, NFT_ABI, chainId, signer)
}

export const useMarketPlaceContract = (signer = true) => {
  const { chainId } = useActiveWeb3React()
  return useContract(getMarketPlaceContrAddress(chainId), MARKETPLACE_ABI, signer)
}

export const useMarketPlaceContractMulChain = (chainId: number, signer = true) => {
  return useContractMulChain(getMarketPlaceContrAddress(chainId), MARKETPLACE_ABI, chainId, signer)
}

export const useNftStakingContract = () => {
  const { chainId } = useActiveWeb3React()
  return useContract(getNftStakingContrAddress(chainId), NFT_STAKING_ABI)
}

export const useNftContract = () => {
  const { chainId } = useActiveWeb3React()
  return useContract(getNftContrAddress(chainId), NFT_ABI)
}

export const useCharityContractV2 = (signer = true) => {
  const { chainId } = useActiveWeb3React()
  return useContract(getCharityContrAddress(chainId), CHARITY_ABI, signer)
}

export const useNftCashierContract = () => {
  const { chainId } = useActiveWeb3React()
  return useContract(getNftCashierContrAddress(chainId), NFT_CASHIER_ABI)
}

export const useCharityTreasuryContract = (signer = true) => {
  const { chainId } = useActiveWeb3React()
  return useContract(getCharityTreasuryContrAddress(chainId), CHARITY_TREASURY_ABI, signer)
}

export const useTokenCashierContract = (signer = true) => {
  const { chainId } = useActiveWeb3React()
  return useContract(getTokenCashierContrAddress(chainId), TOKEN_CASHIER_ABI, signer)
}

export const useTokenCashierContractV2 = (signer = true) => {
  const { chainId } = useActiveWeb3React()
  return useContract(getTokenCashierContrV2Address(chainId), TOKEN_CASHIERV2_ABI, signer)
}

export const useClaimHandlerContract = (signer = true) => {
  const { chainId } = useActiveWeb3React()
  return useContract(getClaimHandlerContrAddress(chainId), CLAIM_HANDLER_ABI, signer)
}